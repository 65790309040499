import {LocalStorageStore} from 'bigdatr-style';

type Settings = {
    theme: 'Light' | 'Dark';
};

const {store, useStore} = LocalStorageStore<Settings>('bd-settings', {
    theme: 'Light'
});

export const useSettings = useStore;
export default store;
