import React, {memo, Suspense, useEffect} from 'react';
import useWebsocketSubscription from './useWebsocketSubscription';
import {
    Analytics,
    AnalyticsProvider,
    AuthProvider,
    ErrorBoundary,
    ModalProvider,
    Sentry,
    useAuth,
    TokenViewer,
    useStageFavicon
} from 'bigdatr-style';
import {BrowserRouter} from 'bigdatr-style';
import {Theme} from 'bigdatr-style';
import {Box} from 'bigdatr-style/layout';
import {DataingestRoutesProvider} from './Router';
import AppNavigation from './AppNavigation';
import AuthenticatedRoutes from './AuthenticatedRoutes';
import {useSettings} from './SettingsStore';
import {EntyProvider} from 'react-enty';

const sentry = new Sentry({
    dsn: process.env.SENTRY_DSN ?? '',
    service: 'bigdatr-client-dataingest',
    environment: process.env.STAGE ?? '',
    release: process.env.RELEASE_NAME ?? ''
});
const analytics = new Analytics({sentry});

export default function App() {
    const [settings] = useSettings();
    useStageFavicon();
    return (
        <BrowserRouter>
            <AnalyticsProvider value={analytics}>
                <ModalProvider>
                    <Theme name={settings.theme}>
                        <ErrorBoundary>
                            <Suspense fallback={null}>
                                <AuthProvider>
                                    <EntyWithAuthAndViewer />
                                </AuthProvider>
                            </Suspense>
                        </ErrorBoundary>
                    </Theme>
                </ModalProvider>
            </AnalyticsProvider>
        </BrowserRouter>
    );
}

function EntyWithAuthAndViewer() {
    const authData = useAuth();
    const {tokenViewer, teamId} = authData;

    // A lot of our data is tainted by the current team id being sent through headers
    // This trashes enty state if the team changes and avoids any tainted entities
    return (
        <EntyProvider key={teamId} meta={authData}>
            <AuthenticatedView tokenViewer={tokenViewer} />
        </EntyProvider>
    );
}

const AuthenticatedView = memo(function AuthenticatedViewMemo({
    tokenViewer
}: {
    tokenViewer: TokenViewer;
}) {
    const subMsg = useWebsocketSubscription();

    useEffect(() => {
        subMsg.request();
    }, []);

    return (
        <DataingestRoutesProvider>
            <ModalProvider>
                <AppNavigation username={tokenViewer.user.username} userId={tokenViewer.id} />
                <Box borderTop="outline" height="navigationOffset">
                    <AuthenticatedRoutes />
                </Box>
            </ModalProvider>
        </DataingestRoutesProvider>
    );
});
