import {createRequestHook} from 'react-enty';
import {dataIngestWebsocket} from './Endpoints';
import {ObjectSchema} from 'react-enty';
import {logList, workflowItem, workflowRun, workflowRunList} from './EntitySchema';

export default createRequestHook({
    name: 'websocketMessage',
    schema: new ObjectSchema({
        logList,
        workflowItem,
        workflowRun,
        workflowRunList
    }),
    request: async function* (_: void, meta) {
        const wsConnection = dataIngestWebsocket(meta);

        for await (const message of wsConnection) {
            if (typeof message === 'string') {
                const {type, data} = JSON.parse(message);
                switch (type) {
                    case 'newWorkflowRun': {
                        yield {
                            workflowRunList: {id: data.workflowId, _prepend: true, data: [data]}
                        };
                        break;
                    }
                    case 'updatedWorkflowRun': {
                        yield {workflowRun: data};
                        break;
                    }
                    case 'updatedWorkflow': {
                        yield {workflowItem: data};
                        break;
                    }
                    case 'workflowRunStageLogs': {
                        const id = `${data.workflowId}-${data.workflowRunId}-${data.workflowStageId}`;
                        yield {
                            logList: {
                                id,
                                pages: {NEW: {logs: data.logs, endCursor: null}}
                            }
                        };
                        break;
                    }
                }
            }
        }
    }
});
