import {EntitySchema, ArraySchema, ObjectSchema} from 'react-enty';

export const workflowRun = new EntitySchema('workflowRun', {id: (ii) => ii.uid});

export const schedule = new EntitySchema('workflowSchedule', {id: (ii) => ii.uid});
export const sensor = new EntitySchema('workflowSensor', {id: (ii) => ii.uid});
export const notebookSensor = new EntitySchema('notebookSensor', {id: (ii) => ii.uid});
const workflowTask = new EntitySchema('workflowTask', {id: (ii) => ii.id});

const workflowRunStageIteration = new EntitySchema('workflowRunStageIteration', {
    id: (ii) => ii.uid
});

export const workflowItem = new EntitySchema('workflow', {
    shape: new ObjectSchema({
        recentRuns: new ArraySchema(workflowRun),
        schedules: new ArraySchema(schedule),
        sensors: new ArraySchema(sensor),
        notebookSensors: new ArraySchema(notebookSensor)
    })
});

export const notebookWorkflow = new EntitySchema('notebookWorkflow', {
    shape: new ObjectSchema({
        workflow: workflowItem
    })
});

export const workflowRunStageIterationList = new EntitySchema('workflowRunStageIterationList', {
    id: (ii) => ii.id,
    shape: new ObjectSchema({
        data: new ArraySchema(workflowRunStageIteration)
    }),
    merge: (aa, bb) => {
        let data = bb.data;
        if (bb._after) data = aa.data.concat(bb.data);
        if (bb._prepend) data = bb.data.concat(aa.data);

        return {
            ...aa,
            ...bb,
            data
        };
    }
});

export const workflowTaskList = new EntitySchema('workflowTaskList', {
    id: (ii) => ii.id,
    shape: new ObjectSchema({
        data: new ArraySchema(workflowTask)
    }),
    merge: (aa, bb) => {
        let data = bb.data;
        if (bb._after) data = aa.data.concat(bb.data);
        if (bb._prepend) data = bb.data.concat(aa.data);

        return {
            ...aa,
            ...bb,
            data
        };
    }
});

export const workflowList = new ArraySchema(workflowItem);

export const workflowRunList = new EntitySchema('runList', {
    id: (ii) => ii.id,
    shape: new ObjectSchema({
        data: new ArraySchema(workflowRun)
    }),
    merge: (aa, bb) => {
        let data = bb.data;
        if (bb._after) data = aa.data.concat(bb.data);
        if (bb._prepend) data = bb.data.concat(aa.data);

        return {
            ...aa,
            ...bb,
            data
        };
    }
});

export const logList = new EntitySchema('logList', {
    id: (ii) => ii.id,
    shape: new ObjectSchema({}),
    merge: (aa, bb) => {
        // If requesting the first page this should override any logs in new
        const mergedNew = bb.pages.FIRST_PAGE
            ? []
            : [...(aa.pages.NEW?.logs ?? []), ...(bb.pages.NEW?.logs ?? [])];

        return {
            id: bb.id,
            pages: {
                ...aa.pages,
                ...bb.pages,
                NEW: {
                    endCursor: null,
                    logs: mergedNew
                }
            }
        };
    }
});
