import {Api as ApiType} from '../../../bigdatr-api-dataingest/src/handlers/api';
import superjson from 'superjson';
type Method = 'POST' | 'GET' | 'DELETE';
type Meta = {
    resultKey: string;
    authorizedFetch: <T>(url: string, payload: any, method: Method) => Promise<T>;
    authorizedWebsocket: (url: string) => AsyncGenerator<unknown, void, void>;
    getState: () => any;
};

export function dataIngestWebsocket(meta: Meta): AsyncGenerator<unknown, void, void> {
    return meta.authorizedWebsocket(process.env.BIGDATR_INGEST_API_WS_ENDPOINT || '');
}

export type DataIngesterApi = {
    [K in keyof ApiType]: {
        payload: Parameters<ApiType[K]>[0];
        response: Awaited<ReturnType<ApiType[K]>>;
    };
};

export function dataIngesterApi<M extends keyof ApiType>(method: M) {
    return async (
        payload: Parameters<ApiType[M]>[0],
        meta: Meta
    ): Promise<Awaited<ReturnType<ApiType[M]>>> => {
        const response = await meta.authorizedFetch<Awaited<ReturnType<ApiType[M]>>>(
            `${process.env.BIGDATR_INGEST_API_ENDPOINT || ''}/api`,
            {method, payload},
            'POST'
        );
        return superjson.deserialize<Awaited<ReturnType<ApiType[M]>>>(response as any);
    };
}
