import {Navigation, MenuItem, ThemeToggleSet, useModal} from 'bigdatr-style';
import {IconNewTab} from 'bigdatr-style/icon';
import {Flex} from 'bigdatr-style/layout';
import api from '~/app/Api';
import React from 'react';
import {useEffect} from 'react';
import {useSettings} from './SettingsStore';

function AppNavigation(props: {username: string; userId: string}) {
    const fetchLink = useSageMakerLink({id: props.userId});
    const [snap, store] = useSettings();

    return (
        <Navigation
            wrapper={false}
            items={[
                {
                    children: (
                        <Flex>
                            SageMaker
                            <IconNewTab />
                        </Flex>
                    ),
                    onClick: fetchLink
                },
                {to: '/', children: 'Dashboard'}
            ]}
            dropdownTitle={props.username}
            dropdown={
                <>
                    <ThemeToggleSet value={snap.theme} onChange={(next) => (store.theme = next)} />
                    <MenuItem to="/logout">Logout</MenuItem>
                </>
            }
        />
    );
}

export default AppNavigation;

function useSageMakerLink({id}: {id: string}) {
    const modal = useModal();
    const message = api.useGetSageMakerLink({key: id});

    const fetchLink = () => {
        modal.pending('Making the sage');
        message.request();
    };

    if (message.isError) {
        message.reset();
        modal.close();
        if (message.requestError.statusCode === 403) {
            modal.toastError('This user does not have access to SageMaker');
        } else {
            throw message.requestError;
        }
    }

    useEffect(() => {
        if (document.body && message.isSuccess) {
            const link = document.createElement('a');
            link.style.display = 'none';
            link.href = message.response?.authorizedUrl || '';
            link.target = '_blank';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            modal.close();
        }
    }, [message.isSuccess]);

    return fetchLink;
}
