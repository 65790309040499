import React from 'react';
import {Switch} from 'bigdatr-style';
import {routes} from './Router';

export default function AuthenticatedRoutes() {
    return (
        <Switch>
            {routes.dashboard}
            {routes.workflow}
            {routes.notebookWorkflowForm}
            {routes.notFound}
        </Switch>
    );
}
