import {Flex} from '../../../layout';
import {IconSun, IconMoon} from '../../../icon';
import React from 'react';
import type {ThemeType} from '../../core/Theme';
import ToggleSet from '../ToggleSet';

export default function ThemeToggleSet(props: {
    value: ThemeType['name'];
    onChange: (next: ThemeType['name']) => void;
}) {
    return (
        <ToggleSet
            multi={false}
            value={props.value}
            onChange={props.onChange}
            options={[
                {
                    value: 'Light',
                    'aria-label': 'Light theme',
                    label: (
                        <Flex alignItems="center" gap={2}>
                            <IconSun /> Light
                        </Flex>
                    )
                },
                {
                    value: 'Dark',
                    'aria-label': 'Dark theme',
                    label: (
                        <Flex alignItems="center" gap={2}>
                            Dark <IconMoon />
                        </Flex>
                    )
                }
            ]}
        />
    );
}
