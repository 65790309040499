import {createRequestHook} from 'react-enty';
import {dataIngesterApi, DataIngesterApi} from './Endpoints';
import {
    workflowList,
    workflowItem,
    workflowTaskList,
    workflowRunList,
    workflowRun,
    notebookWorkflow,
    notebookSensor,
    schedule,
    sensor,
    logList,
    workflowRunStageIterationList
} from './EntitySchema';

type EntyPagintation = {id?: string; _after?: number | string};

//
// Workflows
const useWorkflowItem = createRequestHook({
    name: 'workflowItem',
    schema: workflowItem,
    request: dataIngesterApi('workflowItem')
});

const useWorkflowList = createRequestHook({
    name: 'workflowList',
    schema: workflowList,
    request: dataIngesterApi('workflowList')
});

const useWorkflowSchedulePost = createRequestHook({
    name: 'workflowSchedulePost',
    schema: schedule,
    request: dataIngesterApi('workflowSchedulePost')
});

const useWorkflowSensorPost = createRequestHook({
    name: 'workflowSensorPost',
    schema: sensor,
    request: dataIngesterApi('workflowSensorPost')
});

//
// Workflow Runs
const useWorkflowRunList = createRequestHook({
    name: 'workflowRunList',
    schema: workflowRunList,
    request: async (
        payload: DataIngesterApi['workflowRunList']['payload'],
        meta
    ): Promise<DataIngesterApi['workflowRunList']['response'] & EntyPagintation> => {
        const method = dataIngesterApi('workflowRunList');
        const workflowRunList = await method(payload, meta);
        return {id: payload.workflowId, _after: payload.pageAfter, ...workflowRunList};
    }
});

const useWorkflowRunItem = createRequestHook({
    name: 'workflowRunItem',
    schema: workflowRun,
    request: dataIngesterApi('workflowRunItem')
});

const useWorkflowRunItemInputData = createRequestHook({
    name: 'workflowRunItemInputData',
    request: dataIngesterApi('workflowRunItemInputData')
});

const useWorkflowTaskPost = createRequestHook({
    name: 'workflowTaskPost',
    schema: workflowTaskList,
    request: async (
        payload: DataIngesterApi['workflowTaskPost']['payload'] & {pageAfter?: string},
        meta
    ): Promise<DataIngesterApi['workflowTaskList']['response'] & EntyPagintation> => {
        await dataIngesterApi('workflowTaskPost')(payload, meta);

        const method = dataIngesterApi('workflowTaskList');
        const workflowTaskList = await method(payload, meta);
        return {id: payload.workflowId, _after: payload.pageAfter, ...workflowTaskList};
    }
});

const useWorkflowRunCancelPost = createRequestHook({
    name: 'workflowRunCancelPost',
    request: dataIngesterApi('workflowRunCancelPost')
});

const useWorkflowRunStageLogList = createRequestHook({
    name: 'workflowRunStageLogList',
    schema: logList,
    request: async (vars: DataIngesterApi['workflowRunStageLogList']['payload'], meta) => {
        const method = dataIngesterApi('workflowRunStageLogList');
        const logList = await method(vars, meta);
        const {workflowId, runId, stageId} = vars;
        const id = `${workflowId}-${runId}-${stageId}`;
        return {
            id,
            pages: {
                [vars.pageAfter || 'FIRST_PAGE']: {
                    endCursor: logList.meta.page.endCursor,
                    logs: logList.data
                }
            }
        };
    }
});

const useWorkflowRunStageIterationList = createRequestHook({
    name: 'workflowRunList',
    schema: workflowRunStageIterationList,
    request: async (
        payload: DataIngesterApi['workflowRunStageIterationList']['payload'],
        meta
    ): Promise<DataIngesterApi['workflowRunStageIterationList']['response'] & EntyPagintation> => {
        const method = dataIngesterApi('workflowRunStageIterationList');
        const data = await method(payload, meta);
        return {id: payload.workflowId, _after: payload.pageAfter, ...data};
    }
});

//
// Tasks
const useWorkflowTaskList = createRequestHook({
    name: 'workflowTaskList',
    schema: workflowTaskList,
    request: async (
        payload: DataIngesterApi['workflowTaskList']['payload'],
        meta
    ): Promise<DataIngesterApi['workflowTaskList']['response'] & EntyPagintation> => {
        const method = dataIngesterApi('workflowTaskList');
        const workflowTaskList = await method(payload, meta);
        return {id: payload.workflowId, _after: payload.pageAfter, ...workflowTaskList};
    }
});

//
// Notebook
const useNotebookWorkflowItem = createRequestHook({
    name: 'notebookWorkflowItem',
    schema: notebookWorkflow,
    request: dataIngesterApi('notebookWorkflowItem')
});

const useNotebookWorkflowCreateItem = createRequestHook({
    name: 'notebookWorkflowCreateItem',
    schema: notebookWorkflow,
    request: dataIngesterApi('notebookWorkflowCreateItem')
});

const useNotebookWorkflowRemoveItem = createRequestHook({
    name: 'notebookWorkflowRemoveItem',
    schema: notebookWorkflow,
    request: dataIngesterApi('notebookWorkflowRemoveItem')
});

const useNotebookWorkflowUpdateItem = createRequestHook({
    name: 'notebookWorkflowUpdateItem',
    schema: notebookWorkflow,
    request: dataIngesterApi('notebookWorkflowUpdateItem')
});

const useNotebookWorkflowSensorPost = createRequestHook({
    name: 'notebookWorkflowSensorPost',
    schema: notebookSensor,
    request: dataIngesterApi('workflowNotebookSensorPost')
});

//
// Notebook Utilities
const useGetSageMakerLink = createRequestHook({
    name: 'getSageMakerLink',
    request: dataIngesterApi('getSageMakerLink')
});

const useNotebookCommits = createRequestHook({
    name: 'notebookCommits',
    request: dataIngesterApi('notebookCommits')
});

const useNotebookConfigurationOptions = createRequestHook({
    name: 'notebookConfigurationOptions',
    request: dataIngesterApi('notebookConfigurationOptions')
});

const useNotebookHtml = createRequestHook({
    name: 'notebookHtml',
    request: dataIngesterApi('notebookHtml')
});

const useNotebookList = createRequestHook({
    name: 'notebookList',
    request: dataIngesterApi('notebookList')
});

export default {
    //
    // Workflows
    useWorkflowList,
    useWorkflowItem,
    // useWorkflowScheduleItem - exists in the api but not used in the client
    useWorkflowSchedulePost,
    // useWorkflowSensorItem - exists in the api but not used in the client
    useWorkflowSensorPost,

    //
    // Workflow Runs
    useWorkflowRunList,
    useWorkflowRunItem,
    useWorkflowRunItemInputData,
    useWorkflowRunCancelPost,
    useWorkflowRunStageLogList,
    useWorkflowRunStageIterationList,

    //
    // Tasks
    useWorkflowTaskList,
    useWorkflowTaskPost,

    //
    // Notebook Workflows
    useNotebookWorkflowItem,
    useNotebookWorkflowCreateItem,
    useNotebookWorkflowRemoveItem,
    useNotebookWorkflowUpdateItem,
    useNotebookWorkflowSensorPost,

    //
    // Notebook Utilities
    useGetSageMakerLink,
    useNotebookCommits,
    useNotebookConfigurationOptions,
    useNotebookHtml,
    useNotebookList
};
